// eslint-disable-next-line no-restricted-globals

import React from 'react'
import HTMLFlipBook from "react-pageflip"
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import Lottie from 'react-lottie';

import './CompanyProfile.css'
import IconLoading from '../../assets/animations/icon_pulsing.js'

import ProfilePDF from '../../assets/company_profile.pdf'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle3/PageTitle3'

import ArrowLeft from '../../assets/svg_icons/arrow_left.svg'
import ArrowRight from '../../assets/svg_icons/arrow_right.svg'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="page page-cover" ref={ref} data-density="hard">
      <div className="page-content">
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef(({ pageNumber }, ref) => {
  return (
    <div ref={ref} className="profile-page">
      <ReactPdfPage pageNumber={pageNumber} width={calculatePageWidth()} />
    </div>
  );
});

const calculatePageWidth = () => {
  if (window.screen.width >= 1025 ) {
    return 430
  } else if (window.screen.width >= 481 ) {
    return 400
  } else if (window.screen.width >= 1025 ) {
    return 350
  } else {
    return 350
  }
}

export default class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      totalPage: 23,
    };
  }

  nextButtonClick = () => {
    // this.flipBook.flipNext()
    this.flipBook.pageFlip().flipNext();
  };

  prevButtonClick = () => {
    // this.flipBook.flipPrev()
    this.flipBook.pageFlip().flipPrev();
  };

  onPage = (e) => {
    this.setState({
      ...this.state,
      page: e.data,
    });
  };

  onDocumentLoadSuccess =({ numPages }) =>{
    this.setState({
      ...this.state,
      totalPage: numPages,
    })
  }

  renderLoading = () => {
    return(
    <div className='pdf-loading'>
      <Lottie 
        options={{
          loop: true,
          autoplay: true, 
          animationData: IconLoading,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={100}
        width={100}
      />
    </div>)
  }

  render() {
    // return(
    //   <div>Hello world</div>
    // )
    return (
      <div className='profile-container'>
        <div className='profile-body'>
          <PageTitle 
            title='Company Profile'
          />  
          <Document 
            file={ProfilePDF} 
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={this.renderLoading}
          >
            <HTMLFlipBook
              width={calculatePageWidth()} 
              height={calculatePageWidth()} 
              size="stretch"
              minWidth={calculatePageWidth()}
              maxWidth={calculatePageWidth()}
              minHeight={calculatePageWidth()}
              maxHeight={calculatePageWidth()}
              maxShadowOpacity={0.2}
              showCover={true}
              mobileScrollSupport={true}
              onFlip={this.onPage}
              className="profile-book"
              ref={(el) => (this.flipBook = el)}
            >
              {
                (Array.from(Array(23).keys())).map((item, index) => {
                  return  (
                    <Page pageNumber={index + 1} key={index} />
                  )
                })
              }
            </HTMLFlipBook>
          </Document>

          <div className="profile-flip-control">              

            <div 
              onClick={this.prevButtonClick}
              className='profile-flip-control-btn-1'
            >
              <img 
                src={ArrowLeft}
                className='profile-flip-control-btn-icon'
              />                
            </div>

            <span className='profile-flip-control-count'>{(this.state.page + 1)} of {this.state.totalPage}</span>
            <div 
              onClick={this.nextButtonClick}
              className='profile-flip-control-btn-2'
            >
              <img 
                src={ArrowRight}
                className='profile-flip-control-btn-icon'
              />                
            </div>
          </div>
          <Footer />
        </div>       
      </div>
    );
  }
}