import React from 'react'

import './AboutUs.css'

import Img from '../../assets/svg_icons/404.svg'


export default function Page404(props) {
  return (
    <div className='about-us-container'>      
      <div className='page-404-body'>
        <img 
          src={Img}
        />
        <a href='/'>
          GO TO HOMEPAGE
        </a>
      </div>
    </div>
  )
}