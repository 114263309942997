import * as React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ReactNotifications } from 'react-notifications-component'

import './App.css';
import 'react-notifications-component/dist/theme.css'
import FloatingWhatsApp from 'react-floating-whatsapp'

import NavBar from './components/NavBar/NavBar'

import AboutUs from './pages/AboutUs/AboutUs'
import Page404 from './pages/AboutUs/Page404'
import ContactUs from './pages/ContactUs/ContactUs'
import GPP from './pages/Compliance/GPP'
import HSE from './pages/Compliance/HSE'
import QPS from './pages/Compliance/QPS'
import Certifications from './pages/Compliance/Certifications'
import Home from './pages/Home/Home'
import OurServices from './pages/Services/OurServices'
import MaritimeSecurityServices from './pages/Services/MaritimeSecurityServices'
import VesselManagement from './pages/Services/VesselManagement'
import TechnicalSupport from './pages/Services/TechnicalSupport'
import ShipRepairs from './pages/Services/ShipRepairs'
import OurVessels from './pages/OurVessels/OurVessels'
import CompanyProfile from './pages/CompanyProfile/CompanyProfile'

import GMSIcon from './assets/images/logo512.png'

const drawerWidth = 240;


function App() {
  var whatsapp_support  = ["2347025005014", "2347025005015"]

  return (
    <div className="App">
      <ReactNotifications />      
      <Router>        
        <NavBar/>        
        <Routes>
          <Route path="/" element={<Home />} />          
          <Route path="/404" element={<Page404 />} />          
          <Route path={`/about-us`} element={<AboutUs />} />
          <Route path={`/contact-us`} element={<ContactUs />} />
          <Route path={`/company-profile`} element={<CompanyProfile />} />
          <Route path={`/our-vessels`} element={<OurVessels />} />
          <Route path={`/grievance-policy-and-procedures`} element={<GPP />} />
          <Route path={`/hse`} element={<HSE />} />
          <Route path={`/certifications`} element={<Certifications />} />
          <Route path={`/quality-policy-statement`} element={<QPS />} />
          <Route path={`/services`} element={<OurServices />} />
          <Route path={`/services/maritime-security-services`} element={<MaritimeSecurityServices />} />
          <Route path={`/services/vessel-management`} element={<VesselManagement />} />
          <Route path={`/services/technical-vessel-operations-support`} element={<TechnicalSupport />} />
          <Route path={`/services/ship-epairs--dry-docking`} element={<ShipRepairs />} />

          <Route path="*" element={<Navigate to='/404' />} />         
        </Routes>
      </Router>
      <FloatingWhatsApp 
        accountName="Support"
        phoneNumber={whatsapp_support[Math.floor(Math.random() * whatsapp_support.length)]}
        allowClickAway
        allowEsc
        statusMessage="Online"
        avatar={GMSIcon}
      />
    </div>
  );
}

export default App;

