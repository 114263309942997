import React from 'react'

import './StaffSignature.css'

export default function StaffSignature(props) {
  return (
    <div className='signature-section'>
      <p className='signature'>
        {props.signature_text}
      </p>
      <p className='staff-name'>
        {props.staff_name}
      </p>
      <p className='staff-position'>
        {props.staff_position}
      </p>
    </div>
  )
}

StaffSignature.defaultProps = {
  signature_text: 'Andrew Aligbe',
  staff_name: 'ENGR. Andrew Aligbe',
  staff_position: 'Chief Executive Officer'
}