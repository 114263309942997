import React from 'react'

import './Services.css'

import Footer from '../../components/Footer/Footer'
import PageTitle3 from '../../components/PageTitle3/PageTitle3'

import CheckMark from '../../assets/svg_icons/check_mark.svg'
import Manning from '../../assets/images/manning.png'

export default function VesselManagement(props) {
  return (
    <div className='services-container'>
      <div className='services-body'>
        <PageTitle3
          title='Vessel Management'
        />
        <div className='service-details'>
          <div className='service-details-content'>
            <div className='sub-service'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Vessel Management Services'
                />
                <p>
                  Vessel Management Services
                </p>
              </div>
              <p className='sub-service-p-1'>
                We Provide comprehensive Vessel Management Services and help our clients manage their vessels, from Crewing to providing spares for the smooth operation of the vessel, we have our clients covered 100%.
              </p>
              <p className='sub-service-p-2'>
                In providing these services, we support our clients with managing crew deployment, Vessel Certification, Technical Maintenance & Spares, and managing the operability of the vessel so our client can be at rest while the vessel is at work.
              </p>
            </div>
            
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Manning Services'
                />
                <p>
                  Crewing Services 
                </p>
              </div>
              <p className='sub-service-p-1'>
                We are a licensed crewing agent. We recruit certified, highly competent, and reliable personnel with excellent communication skills for guaranteed performance. We understand the importance of shore-based support-staff to smooth operations, so we recruit on-demand, competent, and experienced support-staff.
              </p>
            </div>
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Charter Services'
                />
                <p>
                  Charter Services
                </p>
              </div>
              <p className='sub-service-p-1'>
                We own a fleet of vessels available for charter. We also source purpose-built vessels for our clients as-and-when required.
              </p>
            </div>
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Bunker Supply'
                />
                <p>
                  Bunker Supply
                </p>
              </div>
              <p className='sub-service-p-1'>
                We are dedicated to providing top quality bunker for vessels at the prevailing market prices.
              </p>
            </div>
          </div>
          <div className='service-details-img'>
            <img 
              src={Manning}
              alt='Vessel Management'
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}