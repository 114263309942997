import React, {
  useState,
} from 'react'

import './Compliance.css'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'
import StaffSignature from '../../components/StaffSignature/StaffSignature'

import CheckMark from '../../assets/svg_icons/check_mark.svg'

export default function QPS(props) {
  return (
    <div className='compliance-container'>
      <div className='compliance-body'>
        <PageTitle 
          title='Quality Policy Statement'
        />  
        <div className='compliance-contents'>
          <p className='compliance-p-1'>
            <p style={{fontWeight: 'bolder', color: 'white', display: 'inline'}}>GELOSE MARINE SERVICES NIGERIA LIMITED (GMSNL)</p> is an indigenous private maritime security company in Nigeria that provides Technical & Vessel Operational Support, Marine Asset & Risks Management, Vessel Management, Brokerage, and Charter Services.
          </p>
          <p className='compliance-p-2'>
            “In our service delivery, we are focused on quality, innovative, and cutting-edge solutions tailored to meet the present-day challenges of the oil and gas industry. We are committed to ensuring customer satisfaction, understanding the needs of other relevant interested parties, adherence to all applicable requirements and the continual improvement of the QMS by constantly reviewing elements of this policy for suitability during management review meetings”.
          </p>
          <p className='compliance-p-2'>
            This statement of our commitment is appropriate to the scope and nature of our operations defined in the Scope of our Integrated Management System (IMS); the Context of our Organization as detailed in our Apex Manual, Context of the Organization Procedure, Internal & External Issues Logs, and Needs & Expectations of our relevant Interested Parties and also supports our strategic direction as detailed in the Business Plan.
          </p>
          
          <p className='compliance-p-2'>
            <p style={{fontWeight: 'bolder', color: 'white', display: 'inline'}}>This statement provides a framework for establishing and reviewing of our HSE objectives</p> which shall be developed by each process head and approved by top management.
          </p>
          <p className='compliance-p-2'>
            This policy statement is communicated and understood by all personnel and applied within the organization, available to relevant interested parties as appropriate to our organization, and maintained as documented information.
          </p>
          <StaffSignature />
        </div>
        <Footer />
      </div>
    </div>
  )
}