import React from 'react'

import './PageTitle2.css'

export default function PageTitle2 (props) {
  return (
    <div className='page-title-2'>
      <div className='page-title-2-box'>
        <p className='page-title-2-first-char'>{props.title[0]}</p>              
      </div>
      <p className='page-title-2-chars'>{props.title.substring(1)}</p>
    </div>
  )
}

PageTitle2.defaultProps = {
  title: 'About Us',
}