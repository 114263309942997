// export const baseURL = 'https://gelosemarine.horminn.com/api/';
// export const baseURL2 = 'https://gelosemarine.horminn.com';

export const baseURL = 'https://api.gelosemarine.com/api/';
export const baseURL2 = 'https://api.gelosemarine.com';


export const fetch_vessels = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'vessels/fetch_vessels'
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const fetch_certifications = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'certifications/fetch_certifications'
      var response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const contact_form = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'prospects/contact_form'
      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params
        })
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}

export const request_vessel_quote = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      var url = baseURL + 'prospects/request_vessel_quote'
      var response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          ...params
        })
      })
      return resolve(response)
    } catch (error) {
      return reject(error)
    }
  })
}