import React from 'react'

import './Services.css'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'

import MaritimeSecurityImg from '../../assets/images/maritime_security_services.png'
import ShipRepairs from '../../assets/images/ship_repairs.png'
import TechSupport from '../../assets/images/bg_9.jpg'
import VesselManagement from '../../assets/images/bg_10.jpg'

export default function OurServices(props) {
  return(
    <div className='services-container'>
      <div className='services-body'>
        <PageTitle 
          title='Our Services'
        />   
        <div className='services-grid'>
          <div className='services-card'>
            <img 
              src={MaritimeSecurityImg}
              alt='Maritime Security Services'
            />
            <p className='service-title'>
              Maritime Security Services
            </p>
            <div className='service-title-underline' />
            <p className='service-descr'>
              We coordinate and track our escort operations from our fully equipped 24-hour manned operational base.
            </p>
            <a 
              className='service-btn'
              href='/services/maritime-security-services'
            >
              Learn More
            </a>
          </div>
          <div className='services-card'>
            <img 
              src={VesselManagement}
              alt='Vessel Management'
            />
            <p className='service-title'>
              Vessel Management
            </p>
            <div className='service-title-underline' />
            <p className='service-descr'>
              We provide our clients with a comprehensive range of maritime support/security vessels.
            </p>
            <a 
              className='service-btn'
              href='/services/vessel-management'
            >
              Learn More
            </a>
          </div>
          <div className='services-card'>
            <img 
              src={TechSupport}
              alt='Technical & Vessel Operational Support'
            />
            <p className='service-title'>
              Technical & Vessel Operational Support
            </p>
            <div className='service-title-underline' />
            <p className='service-descr'>
              We plan, supervise, and execute ship maintenance projects and activities to ensure compliance with specifications and standards.
            </p>
            <a 
              className='service-btn'
              href='/services/technical-vessel-operations-support'  
            >
              Learn More
            </a>
          </div>
          <div className='services-card'>
            <img 
              src={ShipRepairs}
              alt='Ship Repairs & Dry Docking'
            />
            <p className='service-title'>
              Ship Repairs & Dry Docking
            </p>
            <div className='service-title-underline' />
            <p className='service-descr'>
              We provide ship repairs, redesign and construction to suit modern needs for retrofitting, enhance operability of the vessel or enhance the ships aesthetics.
            </p>
            <a 
              className='service-btn'
              href='/services/ship-epairs--dry-docking'
            >
              Learn More
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}