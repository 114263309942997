import React from 'react'

import './PageTitle.css'

export default function PageTitle (props) {
  return (
    <div className='page-title'>
      <div className='page-title-box'>
        <p className='page-title-first-char'>{props.title[0]}</p>              
      </div>
      <p className='page-title-chars'>{props.title.substring(1)}</p>
    </div>
  )
}

PageTitle.defaultProps = {
  title: 'About Us',
}