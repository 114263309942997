import React from 'react'

import './Services.css'

import Footer from '../../components/Footer/Footer'
import PageTitle3 from '../../components/PageTitle3/PageTitle3'

import CheckMark from '../../assets/svg_icons/check_mark.svg'
import MaritimeSecurityImg2 from '../../assets/images/tech-support.png'

export default function TechnicalSupport(props) {
  return (
    <div className='services-container'>
      <div className='services-body'>
        <PageTitle3
          title='Technical & Vessel Operational Support'
        />
        <div className='service-details'>
          <div className='service-details-content'>
            <div className='sub-service'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Operations & Logistics support'
                />
                <p>
                  Operations & Logistics support
                </p>
              </div>
              <p className='sub-service-p-1'>
                Gelose Marine Services provides excellent on-shore and off-shore support to vessels in our care. We always ensure round-the-clock monitoring of vessel operations. In the case of any unforeseen incidence that may arise in the course of operations, we respond speedily and exceed our clients’ expectations.
              </p>
            </div>
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Technical consultancy'
                />
                <p>
                  Technical consultancy
                </p>
              </div>
              <p className='sub-service-p-1'>
                At Gelose Marine Services, we plan, supervise and execute ship maintenance projects and activities (basic and detailed) to ensure compliance with specifications and standards. We carryout heavy-duty ship machineries repair (Engines, Cranes, Automation and Controls, Compressors, Gearboxes etc).
              </p>
            </div>
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Marine Condition & Warranty Surveys'
                />
                <p>
                  Marine Condition & Warranty Surveys 
                </p>
              </div>
              <p className='sub-service-p-1'>
                We specialize in marine condition surveys. We Undertake engineering assessment, damage survey and failure analysis.
              </p>
            </div>
          </div>
          <div className='service-details-img'>
            <img 
              src={MaritimeSecurityImg2}
              alt='Technical & Vessel Operational Support'
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}