import React, {
  useEffect,
  useState,
} from 'react'
import Modal from 'react-modal';
import { Store } from 'react-notifications-component';

import './OurVessels.css'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'

import Vessel1 from '../../assets/images/vessel_1.png'
import Vessel2 from '../../assets/images/vessel_2.png'
import CloseIcon from '../../assets/svg_icons/close_1.svg'

import {baseURL2, request_vessel_quote, fetch_vessels} from '../../api'
import Loading from '../../components/Loading/Loading';

export default function OurVessels(props) {  
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [is_submitting, setSubmit] = useState(false)
  const [input_values, setInputValues] = useState({
    date_of_task: '',
    duration_of_task: '',
    speed_required: '',
    escort_days: '',
    deploy_recovery_days: '',
    standby_days: '',
    stowaway_search: '',
    full_name: '',
    phone_no: '',
  })
  const [veessels, setVessels] = useState([])
  const [active_vessel, setActiveVesssel] = useState({
    name: '',
    availability: '',
    year: '',
    type: '',
    classification: '',
    description: '',
    length_overall: '',
    breadth_moulded: '',
    depth_moulded: '',
    gross_tonnage: '',
    passenger_capacity: '',
    main_engines: '',
    propulsion: '',
    bow_thruster: '',
    speed: '',
    horse_power: '',
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    _fetch_vessels()
  }, [])

  const _fetch_vessels = async () => {
    try {
      setLoading(true)
      var res = await fetch_vessels()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setVessels(response.data)
        setLoading(false)
      } else {
        console.log('response message', response.message)
        setVessels([])
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // setLoading(false)
      }
    } catch (error) {
      console.log('error message', error.message)
      setVessels([])
      setLoading(false)
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }

  function openModal(item) {
    setActiveVesssel(item)
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  let vessel_status= 'available'
  const customStyles = {
    content: {
      // top: '50%',
      // left: '50%',
      // right: 'auto',
      // bottom: 'auto',
      // marginRight: '-50%',
      zIndex: 9999999999999999,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      backdropFilter: 'blur(20px)',
    }
  };

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
    console.log('input', value)
  }


  const sanitize_msg = msg => {
    if(typeof msg === 'string') {
      // remove urls
      var text = msg.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
      // remove html tags
      return text.replace(/<\/?[^>]+(>|$)/g, "");
    }
    return msg
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    const email_message = `Hi Admin,

    Someone just filled the request a quote form the website.

    Full Name: ${sanitize_msg(input_values.full_name)}
    Phone Number: ${sanitize_msg(input_values.phone_no)}
    Date of Task: ${sanitize_msg(input_values.date_of_task)}
    Duration of Task: ${sanitize_msg(input_values.duration_of_task)}
    Speed Requried: ${sanitize_msg(input_values.speed_required)}
    Escort Days: ${sanitize_msg(input_values.escort_days)}
    Deploy / Recovery Days: ${sanitize_msg(input_values.deploy_recovery_days)}
    Standby Days: ${sanitize_msg(input_values.standby_days)}
    Stowaway Search: ${sanitize_msg(input_values.stowaway_search)}

    Warm Regards,
    Gelose Marine Services - Support Team
    `

    const subject = `${active_vessel.name} Quote Request  by: ${input_values.full_name}`

    try {
      var params = {
        email: sanitize_msg(input_values.email),
        full_name: sanitize_msg(input_values.full_name),
        subject,
        message: email_message,
        phone_no: sanitize_msg(input_values.phone_no),
        date_of_task: sanitize_msg(input_values.date_of_task),
        duration_of_task: sanitize_msg(input_values.duration_of_task),
        speed_required: sanitize_msg(input_values.speed_required),
        escort_days: sanitize_msg(input_values.escort_days),
        deploy_recovery_days: sanitize_msg(input_values.deploy_recovery_days),
        standby_days: sanitize_msg(input_values.standby_days),
        stowaway_search: sanitize_msg(input_values.stowaway_search),
        vessel: active_vessel.id,
      }
      var res = await request_vessel_quote(params)
      var response = await res.json()
      if (res.ok) {
        setInputValues({
          date_of_task: '',
          duration_of_task: '',
          speed_required: '',
          escort_days: '',
          deploy_recovery_days: '',
          standby_days: '',
          stowaway_search: '',
          full_name: '',
          phone_no: '',
        })
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        console.log('response message', response.message)
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // setLoading(false)
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
  }
 
  
  return (
    <div className='vessels-container'>
      <div className='vessels-body'>
        <PageTitle 
          title='Our Vessels'
        />  
        {
          loading && (
            <Loading />
          )
        } 

        {
          !loading && (
            <div className='vessels-grid'>
              {
                veessels.map((item, index) => {
                  return (
                    <div 
                      className='vessel-card'     
                      key={index}       
                    >
                      <img 
                        src={baseURL2 + item.image}
                        alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
                      />
                      <p className='vessel-title'>
                        {item.name}
                      </p>
                      <div className='vessel-title-underline' />
                      <div className='vessels-stats'>
                        <div className='vessels-stats-item'>
                          <p className='vessels-stats-item-label'>
                            Status
                          </p>
                          <div className={`vessels-stats-item-status status-${item.availability.toLowerCase().replace(" ", "-")}`}>
                            <p>{item.availability}</p>
                          </div>                  
                        </div>
                        <div className='vessels-stats-item'>
                          <p className='vessels-stats-item-label'>
                            Type
                          </p>
                          <p className='vessels-stats-item-value'>
                            {item.type}
                          </p>                
                        </div>
                        <div className='vessels-stats-item'>
                          <p className='vessels-stats-item-label'>
                            Class
                          </p>
                          <p className='vessels-stats-item-value'>
                            {item.classification}
                          </p>                
                        </div>
                      </div>
                      <div 
                        className='vessel-btn'
                        onClick={() => openModal(item)}
                      >
                        <p>See More</p>
                      </div>
                    </div>
                  )
                })
              }
              
            </div>
          )
        }
        
        <Footer />
        
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        // contentLabel="Vessel Modal"
        // overlayClassName="modal-overlay"
        className="vessel-modal" 
      >
        <button
          className='modal-close-button'
          onClick={closeModal}
        >
          <img 
            src={CloseIcon}
            alt='Close Button'
          />
        </button>
        <div className='vessel-modal-contents'>
          <div className='vessel-modal-info'>
            <div
              className='vessel-modal-title'
            >
              <p>{active_vessel.name}</p>
              <div/>
            </div> 
            <img
              src={baseURL2 + active_vessel.image}
              className='vessel-modal-img'
              alt={`MV ODIANOSEN - Private Marine Company in  Nigeria`}
            />
            <p className='vessel-info-descr'>
              {active_vessel.description}
            </p>  
            <div className='vessel-overview'>
              <div className='vessels-overview-item'>
                <p className='vessels-overview-item-label'>
                  Status
                </p>
                
                <div className='vessels-overview-item-status'>
                  <div className={`vessels-stats-item-status status-${active_vessel.availability.toLowerCase().replace(" ", "-")}`}>
                    <p>{active_vessel.availability}</p>
                  </div>                  
                </div>                  
              </div>
              <div className='vessels-overview-item'>
                <p className='vessels-overview-item-label'>
                  Type
                </p>
                <p className='vessels-overview-item-value'>
                  {active_vessel.type}
                </p>                
              </div>
              <div className='vessels-overview-item'>
                <p className='vessels-overview-item-label'>
                  Class
                </p>
                <p className='vessels-overview-item-value'>
                  {active_vessel.classification}
                </p>                
              </div>
              <div className='vessels-overview-item'>
                <p className='vessels-overview-item-label'>
                  Year
                </p>
                <p className='vessels-overview-item-value'>
                  {active_vessel.year}
                </p>                
              </div>
            </div>
            <p className='vessel-info-subtitle'>
              Technical Specifications
            </p>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Length Ovearall
              </p>
              <p className='vessels-overview-item-value'>
                {active_vessel.length_overall}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Breadth Moulded
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.breadth_moulded}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Depth Moulded
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.depth_moulded}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Gross Tonnage
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.gross_tonnage}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Passenger Capacity
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.passenger_capacity}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                No of Main Engines
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.main_engines}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Propulsion
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.propulsion}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Max Speed
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.speed}
              </p>                
            </div>
            <div className='vessels-overview-item'>
              <p className='vessels-overview-item-label'>
                Horsepower
              </p>
              <p className='vessels-overview-item-value'>
              {active_vessel.horse_power}
              </p>                
            </div>

            <div className='vessel-request-quote'>
              <p className='vessel-info-subtitle'>
                Request Quote
              </p>
              <form onSubmit={handle_submit} >
                <div className='vessel-quote-form'>
                  <input 
                    type='text' 
                    value={input_values.full_name} 
                    name='full_name'
                    placeholder='Full Name'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='tel' 
                    value={input_values.phone_no} 
                    name='phone_no'
                    placeholder='Phone Number'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='date' 
                    // type="text" 
                    // onfocus="(this.type='date')" onblur="(this.type='text')"
                    // type='text'
                    value={input_values.date_of_task} 
                    name='date_of_task'
                    placeholder='Date of Task - '
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='text' 
                    value={input_values.duration_of_task} 
                    name='duration_of_task'
                    placeholder='Duration of Task'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='text' 
                    value={input_values.speed_required} 
                    name='speed_required'
                    placeholder='Speed Required'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='text' 
                    value={input_values.escort_days} 
                    name='escort_days'
                    placeholder='Escort Days'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='text' 
                    value={input_values.deploy_recovery_days} 
                    name='deploy_recovery_days'
                    placeholder='Deploy / Recovery Days'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='text' 
                    value={input_values.standby_days} 
                    name='standby_days'
                    placeholder='Standby Days'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                  <input 
                    type='text' 
                    value={input_values.stowaway_search} 
                    name='stowaway_search'
                    placeholder='Stowaway Search'
                    onChange={handle_input_change} 
                    required
                    className="vessel-outline-input-field"                 
                  />  
                </div>
                
                <input type="submit" value="Submit" className="contact-submit-button "/>
                
              </form>
            </div>
            
          </div>  
        </div>                   
      </Modal>
    </div>
  )
}