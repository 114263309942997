import React, {
  useState,
} from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './Home.css'

import RightArrow from '../../assets/svg_icons/right_circled_arrow.svg'
import LeftArrow from '../../assets/svg_icons/left_circled_arrow.svg'

import CheckMark from '../../assets/svg_icons/check_mark.svg'

export default function Home(props) {
  const [current_slide_index, setCurrentSlideIndex] = useState(0)
  const TotalSlides = 3

  const next_slide = () => {
    if (current_slide_index + 1  <= TotalSlides) {
      setCurrentSlideIndex(current_slide_index + 1)
    } else {
      setCurrentSlideIndex(0)
    }
  }

  const prev_slide = () => {
    if (current_slide_index - 1  >= 0) {
      setCurrentSlideIndex(current_slide_index - 1)
    } else {
      setCurrentSlideIndex(TotalSlides)
    }
  }

  const update_current_slide = (index) => {
    if (current_slide_index !== index) {
      setCurrentSlideIndex(index)
    }
  };

  return (
    <div className='home-container' scroll='no'>
      <Carousel
        autoPlay
        interval={3000}
        selectedItem={current_slide_index}
        onChange={update_current_slide}
        infiniteLoop
        emulateTouch
        swipeable
        showStatus={false}
        showArrows={false}
      >
        <div className='home-slide-item slide-1'>
          {/* <p className='home-slide-item-index'>
            01
          </p> */}
          <h1 className='home-slide-item-title'>
            {"Leading Private Maritime\nSecurity Company"}
          </h1>
          <p className='home-slide-item-sub-title'>          
            {"We provide a comprehensive range of \nmaritime support and security vessels"}
          </p>
          <a className='btn-1' href='/about-us'>
            Learn More
          </a>
        </div>
        <div className='home-slide-item slide-2 '>
          {/* <p className='home-slide-item-index'>
            02
          </p> */}
          <h1 className='home-slide-item-title'>
            {"Integrity In\nService..."}
          </h1>
          <p className='home-slide-item-sub-title'>
            {"We are engineered to deliver \noptimum services"}
          </p>
          <a className='btn-1' href='/services'>
            Learn More
          </a>
        </div>
        <div className='home-slide-item slide-3 '>
          {/* <p className='home-slide-item-index'>
            02
          </p> */}
          <h1 className='home-slide-item-title'>
          {"The Maritime\nFolks..."}
             
          </h1>
          <p className='home-slide-item-sub-title'>
            {"We deliver timeous services always,\nlets manage your maritime needs"}
          </p>
          <a className='btn-1' href='/services'>
            Learn More
          </a>
        </div>
        <div className='home-slide-item slide-4 '>
          {/* <p className='home-slide-item-index'>
            02
          </p> */}
          <h1 className='home-slide-item-title'>
          {"Onshore & Offshore\nVessel maintenance"}             
          </h1>
          <p className='home-slide-item-sub-title'>          
            {"We are a team of young and highly\ntrained personnel."}
          </p>
          <a className='btn-1' href='/services'>
            Learn More
          </a>
        </div>
      </Carousel>
      <div className='home-footer'>
        <div className='home-slide-controls'>
          <div onClick={prev_slide}>
            <img 
              src={LeftArrow}
              alt='No 1 Private\n Marine Service Company'
              className='home-slide-controls-btns'
            />
          </div>
          <div onClick={next_slide}>
            <img 
              src={RightArrow}
              alt='No 1 Private\n Marine Service Company'
              className='home-slide-controls-btns'
            />
          </div>
        </div>
        <div className='home-remarks'>
          <div className='home-remarks-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
              ISO Certified
            </p>
          </div>
          <div className='home-remarks-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
              Highly Skilled
            </p>
          </div>
          <div className='home-remarks-item'>
            <img 
              src={CheckMark}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
            <p>
              High Tech
            </p>
          </div>
        </div>
      </div>
     
    </div>
  )
} 
