import React, {
  useState,
} from 'react'
import { Store } from 'react-notifications-component';

import './ContactUs.css'

import PageTitle from '../../components/PageTitle/PageTitle'

import LocationIcon from '../../assets/svg_icons/location_1.svg'

import {contact_form} from '../../api'

export default function ContactUs(props) {
  const [input_values, setInputValues] = useState({
    full_name: '',
    email: '',
    subject: '',
    phone_no: '',
    message: '',
  })
  const [is_submitting, setSubmit] = useState(false)

  const sanitize_msg = msg => {
    // remove urls
    var text = msg.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    // remove html tags
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    setSubmit(true)

    const email_message = `Hi Admin,

    Someone just filled the contact form the website.

    Name: ${sanitize_msg(input_values.full_name)}
    Phone: ${sanitize_msg(input_values.phone_no)}
    Email: ${sanitize_msg(input_values.email)}

    Message: ${sanitize_msg(input_values.message)}

    Warm Regards,
    Gelose Marine Services - Support Team
    `

    const subject = `Message from ${input_values.full_name}`

    try {
      var params = {
        email: sanitize_msg(input_values.email),
        full_name: sanitize_msg(input_values.full_name),
        phone_no: sanitize_msg(input_values.phone_no),
        subject,
        message: email_message,
      }
      var res = await contact_form(params)
      
      var response = await res.json()
      if (res.ok) {
        setInputValues({
          full_name: '',
          email: '',
          subject: '',
          phone_no: '',
          message: '',
        })
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        console.log('response message', response.message)
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // setLoading(false)
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    
  }
  return (
    <div className='contact-us-container'>    
      <div className='contact-us-body'>
        <PageTitle 
          title='Contact Us'
        />     
        <div className='contact-details'>
          <div className='contact-msg-n-form'>
            <p className='contact-msg'>
            At Gelose Marine Services Nigeria Ltd, we understand your Maritime needs and we are committed to being the best Private Maritime Security Company in Nigeria while meeting and exceeding your expectations.
            </p>
            <p className='contact-msg-2'>
              We sincerely hope you will afford us the opportunity and responsibility to deliver your maritime needs excellently and on time, every time.
            </p>
            
            <p className='contact-form-title'>
              Talk To Our Team
            </p>
            <form onSubmit={handle_submit}>
              <span className="contact-input-fields-row">
                <input 
                  type='text' 
                  value={input_values.full_name} 
                  name='full_name'
                  placeholder='Full Name'
                  onChange={handle_input_change} 
                  autoComplete='name'
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="contact-outline-input-field-1"                
                />
                <input 
                  type='email' 
                  value={input_values.email} 
                  name='email'
                  placeholder='Email'
                  onChange={handle_input_change} 
                  autoComplete='email'
                  required
                  // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                  className="contact-outline-input-field-2"                 
                />
              </span>
              <input 
                type='tel' 
                value={input_values.phone_no} 
                name='phone_no'
                placeholder='Phone Number'
                onChange={handle_input_change} 
                required
                className="contact-outline-input-field-subject"                 
              />                            
              <textarea 
                value={input_values.message} 
                onChange={handle_input_change} 
                name='message'
                placeholder="Message"
                expa
                required
              />

              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <input type="submit" value="Contact Us" className="contact-submit-button "/>
              </span>
              
              
            </form>
          </div>
          <div className='contact-addresses'>
            <div className='office-box'>
              <div className='office-name-row'>
                <div className='office-location-icon-box'>
                  <img 
                    src={LocationIcon}
                    alt='Best Marine Service Company in Nigeria'
                  />
                </div>
                <p className='office-name'>
                  Administrative Office
                </p>
              </div>
              <p>
                No.11 2nd Avenue, Circular Road off Woji, Alcon Road, Port Harcourt, Nigeria.
              </p>
              <p>
                Phone: <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '12px',
                }} 
                href="tel:+234 906 2807 052"
                >+234 906 2807 052</a> <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '12px',
                }} 
                href="tel:+234 906 2807 049"
                >+234 906 2807 049</a>
              </p>
              <p>
                Email: <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '12px',
                }} 
                href="mailto:info@gelosemarine.com"
                >info@gelosemarine.com</a> <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '12px',
                }} 
                href="mailto:commercial@gelosemarine.com"
                >commercial@gelosemarine.com</a>
              </p>
            </div>
            {/* <div className='office-box office-box-2'>
              <div className='office-name-row'>
                <div className='office-location-icon-box'>
                  <img 
                    src={LocationIcon}
                    alt='Best Marine Service Company in Nigeria'
                  />
                </div>
                <p className='office-name'>
                  Operational Base
                </p>
              </div>
              <p>
                No.11 2nd Avenue, Circular Road off Woji, Alcon Road, Port Harcourt, Nigeria.
              </p>
              <p>
                Phone: <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '14px',
                }} 
                href="tel:+234 7012 3456 789"
                >+234 7012 3456 789</a> <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '14px',
                }} 
                href="tel:+234 7012 3456 789"
                >+234 7012 3456 789</a>
              </p>
              <p>
                Email: <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '14px',
                }} 
                href="tel:+234 7012 3456 789"
                >operations@gelosemarine.com</a> <a 
                style={{
                  fontWeight: 'bolder',
                  textDecoration: 'none',
                  color: '#fff',
                  fontSize: '14px',
                }} 
                href="tel:+234 7012 3456 789"
                >enquires@gelosemarine</a>
              </p>
            </div> */}
          </div>
        </div>
        <div className='footer-rights'>
        <p>
          © Copyright Gelose Marine Services NIG LTD
        </p>
        <p>Designed & Developed by <a 
          style={{
            fontWeight: 'bolder',
            textDecoration: 'none',
            color: '#fff',
            fontSize: '14px',
          }} 
          href="https://www.reebun.com/"
          target="_blank" 
          rel="noopener noreferrer"
          >Reebun</a>
        </p>
      </div>
      </div>
    </div>
  )
}