import React from 'react'

import './PageTitle3.css'

export default function PageTitle3 (props) {
  return (
    <div className='page-title-3'>
      <p className='page-title-3-chars'>{props.title}</p>
      <div className='page-title-3-underline'/>
    </div>
  )
}

PageTitle3.defaultProps = {
  title: 'About Us',
}