import React from 'react'

import './AboutUs.css'

import Img1 from '../../assets/images/about-us-img.png'
import MissionIcon from '../../assets/images/mission.png'
import VisionIcon from '../../assets/images/vision.png'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'
import StaffSignature from '../../components/StaffSignature/StaffSignature'

export default function AboutUs(props) {
  return (
    <div className='about-us-container'>      
      {/* <div className='about-us-title'>
        <div className='about-us-title-box'>
          <p className='about-us-title-first-char'>A</p>              
        </div>
        <p className='about-us-title-chars'>bout Us</p>
      </div> */}
      <div className='about-us-body'>
        <PageTitle 
          title='About Us'
        />        
        <div className='about-us-msg-section'>
          <div className='about-us-msg'>          
            <p className='about-us-msg-intro'>
              Gelose Marine Services Limited is the leading Private Maritime Security Company in Nigeria specializing in Maritime Risk Management, and provisioning of Armed Security Escort Services.
            </p>
            <p className='about-us-msg-content'>
              We deliver high-level Maritime Security Escort Services using our fleet of vessels backed by a Nigerian Navy Memorandum of Understanding (MOU) and supported by a world-class 24-hour operated command center to ensure live support and monitoring for your Assets all year round.
            </p>
            <p className='about-us-msg-content'>
              Our Jetty & Technical Support Yard is equipped to provide you with all the needed Technical Support, Vessel Management, and Ship repairs & Maintenance.
            </p>
            <p className='about-us-msg-content'>
              We provide Technical and Engineering Services, Logistics, Maritime Asset Risk Management, Security Services in collaboration with Government Security Forces (GSF) to both local and international companies on land and at sea.
            </p>
            <p className='about-us-msg-content'>
            Our 400Ton lifting capacity Shipyard which will come on stream in 2023 will further enhance our Vessel Management and repair capabilities to include Ship Drydocking and Underwater repairs.
            </p>
            <StaffSignature />
          </div>
          <div className='about-us-msg-img'>
            <img 
              src={Img1}
              alt='Private Marine Company in Nigeria'
            />
          </div>

        </div>
        
        <div className='mission-vision'>
          <div className='mission-vision-sub'>
            <div className='mission-vision-title'>
              <div className='mission-vision-title-box'>
                <img 
                  src={MissionIcon}
                  alt='Private Marine Company in Nigeria'
                />
              </div>
              <p>Our Mission</p>
            </div>
            <p className='mission-vision-content'>
              To always provide world class quality services safely and timeously, to meet and exceed our clients’ ever growing maritime needs.
            </p>
          </div>
          <div className='mission-vision-sub'>
            <div className='mission-vision-title'>
              <div className='mission-vision-title-box'>
                <img 
                  src={VisionIcon}
                  alt='Private Marine Company in Nigeria'
                />
              </div>
              <p>Our Vision</p>
            </div>
            <p className='mission-vision-content'>
            To be the leading service provider in the maritime asset and risk management industry in Nigeria
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}