import React from 'react'

import './Services.css'

import Footer from '../../components/Footer/Footer'
import PageTitle3 from '../../components/PageTitle3/PageTitle3'

import CheckMark from '../../assets/svg_icons/check_mark.svg'
import MaritimeSecurityImg2 from '../../assets/images/maritime_security_services_3.png'

export default function MaritimeSecurityServices(props) {
  return (
    <div className='services-container'>
      <div className='services-body'>
        <PageTitle3
          title='Maritime Security Services'
        />
        <div className='service-details'>
          <div className='service-details-content'>
            <div className='sub-service'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Security Escorts Service'
                />
                <p>
                  Security Escorts Service
                </p>
              </div>
              <p className='sub-service-p-1'>
                We conduct Marine Security Escorts, using Nigerian Navy approved long range patrol boats manned by Government Security Forces (Navy). We coordinate and track our escort operations from our fully equipped2 4-hour manned operations centre.
              </p>
              <p className='sub-service-p-2'>
                We are experts in vessel operation logistics, providing all logistics services including sourcing for armed personnel, conducting due diligence checks and delivering all the required resources as and when due.
              </p>
            </div>
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Emergency Response'
                />
                <p>
                  Emergency Response
                </p>
              </div>
              <p className='sub-service-p-1'>
              With our partnership with the Nigeria Navy, robust human resource capital, our fleet of fast security intervention vessels, advanced technology and our mutually rewarding work relationship with other maritime security stakeholders, we have the network to respond to marine emergency situations within the Nigerian EEZ.
              </p>
            </div>
            <div className='sub-service-1'>
              <div className='sub-service-checked-title'>
                <img 
                  src={CheckMark}
                  alt='Security Consultancy'
                />
                <p>
                  Security Consultancy
                </p>
              </div>
              <p className='sub-service-p-1'>
              Our Consultancy Services are optimised to provide clients with the confidence to operate competitively in high-risk, complex, and challenging environments. We also provide information on Piracy incidences and activities in and around the Gulf of Guinea and advise on the most practical and safest approach to these security reports. Our state-of-the-art onshore journey management services provides risk assessments and local intelligence to both the private and corporate sectors.
              </p>
            </div>
          </div>
          <div className='service-details-img'>
            <img 
              src={MaritimeSecurityImg2}
              alt='Maritime Security Services'
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}