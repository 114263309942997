import React, {
  useEffect,
  useState,
} from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/react-splide/css';
import { Store } from 'react-notifications-component';

import Cert1 from '../../assets/images/cert_logos/igs.png'
import Cert2 from '../../assets/images/cert_logos/bimco.png'
import Cert3 from '../../assets/images/cert_logos/civil.png'
import Cert4 from '../../assets/images/cert_logos/maspan.png'
import Cert5 from '../../assets/images/cert_logos/navy.png'
import Cert6 from '../../assets/images/cert_logos/ncdmb.png'
import Cert7 from '../../assets/images/cert_logos/nimasa.png'
import Cert8 from '../../assets/images/cert_logos/police.png'

import EmailIcon from '../../assets/svg_icons/email.svg'
import PhoneIcon from '../../assets/svg_icons/phone.svg'
import LocationIcon from '../../assets/svg_icons/location.svg'

import './Footer.css'

import {baseURL2, contact_form, fetch_certifications} from '../../api'

export default function Footer(props) {
  const [input_values, setInputValues] = useState({
    full_name: '',
    email: '',
    phone_no: '',
    message: '',
  })
  const [is_submitting, setSubmit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [certs, setCerts] = useState([])

  useEffect(() => {
    _fetch_certifications()
  }, [])

  const _fetch_certifications = async () => {
    try {
      setLoading(true)
      var res = await fetch_certifications()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setCerts(response.data)
        setLoading(false)
      } else {
        console.log('response message', response.message)
        setCerts([])
      }
    } catch (error) {
      console.log('error message', error.message)
      setCerts([])
    }
  }
  
  const splide_options = {
    type   : 'loop',
    drag   : 'free',
    snap   : true,
    autoPlay: true,
    arrows: false,
    pagination: false,
    interval: 500,
    autoWidth: true,
    // perPage: 3,
    autoScroll: {
      speed: 1,
      autoStart: true,
      rewind: true,
    },
  }

  const slides = [
    Cert1, Cert2, Cert3, Cert4, Cert5, Cert6, Cert7, Cert8
  ]

  const sanitize_msg = msg => {
    // remove urls
    var text = msg.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');
    // remove html tags
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const handle_input_change = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setInputValues({
      ...input_values,
      [name]: value
    });
  }

  const handle_submit = async (event) => {
    event.preventDefault();

    setSubmit(true)

    const email_message = `Hi Admin,

    Someone just filled the contact form the website.

    Name: ${sanitize_msg(input_values.full_name)}
    Email: ${sanitize_msg(input_values.email)}

    Message: ${sanitize_msg(input_values.message)}

    Warm Regards,
    Gelose Marine Services - Support Team
    `

    const subject = `Message from ${input_values.full_name}`

    try {
      var params = {
        email: sanitize_msg(input_values.email),
        full_name: sanitize_msg(input_values.full_name),
        subject,
        message: email_message,
      }
      var res = await contact_form(params)
      var response = await res.json()
      if (res.ok) {
        setInputValues({
          full_name: '',
          email: '',
          subject: '',
          phone_no: '',
          message: '',
        })
        Store.addNotification({
          title: "Success!",
          message: response.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        console.log('response message', response.message)
        Store.addNotification({
          title: "Error!",
          message: response.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // setLoading(false)
      }
    } catch (error) {
      Store.addNotification({
        title: "Error!",
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    }
    
  }

  const options = {
    type         : 'loop',
    autoplay     : true,
    pauseOnHover : false,
    resetProgress: false,
    height       : '15rem',
    arrows: false,
    pagination: false,
    autoWidth: true,
    interval: 500,
    snap   : true,
    drag   : 'free',
  };

  return(
    <div className='footer-container'>
      {
        !loading && props.showCerts && (
          <Splide
            options={ options }
            className='cert-logos-container'
            autoScroll={{
              speed: 1,
              autoStart: true,
              rewind: true,
            }}
            autoPlay
            aria-label='Private Marine Company in Nigeria - Ceritification Logos'
          >
            {
              certs.map((item, index) => (
                <SplideSlide
                  key={index}
                >
                  <img 
                    src={baseURL2 + item.image}
                    className='cert-img'
                    alt={`Private Marine Company in Nigeria - cert logo ${index}`}
                  />
                </SplideSlide>
              ))
            }
          </Splide>
        )
      }
      
      <div className='footer-contact-info-n-form'>
        <div className='footer-contact-info'>
          <div className='footer-contact-info-item'>
            <img 
              src={PhoneIcon}
              className='footer-contact-info-item-icon'
              alt="Gelose Marine Services - No.1 Private Marine Company in Nigeria Support Phone Number"
            />
            <div className='footer-contact-info-item-content'>
              <a href='tel:+234 906 2807 049' className='footer-contact-info-item-link'>
                +234 906 2807 049            
              </a>
              <a href='tel: +234-906-2807-052' className='footer-contact-info-item-link link-2'>
                +234 906 2807 052            
              </a>
            </div>
          </div>
          <div className='footer-contact-info-item-1'>
            <img 
              src={EmailIcon}
              className='footer-contact-info-item-icon'
              alt="Gelose Marine Services - No.1 Private Marine Company in Nigeria Support Email Address"
            />
            <div className='footer-contact-info-item-content'>
              <a href='mailto:commercial@gelosemarine.com' className='footer-contact-info-item-link'>
                enquiries@gelosemarine.com    
              </a>
              <a href='mailto:enquires@gelosemarine.com' className='footer-contact-info-item-link link-2'>
                commercial@gelosemarine.com
              </a>
              <a href='mailto:info@gelosemarine.com' className='footer-contact-info-item-link link-2'>
                info@gelosemarine.com
              </a>
            </div>
          </div>
          <div className='footer-contact-info-item-1'>
            <img 
              src={LocationIcon}
              className='footer-contact-info-item-icon'
              alt="Gelose Marine Services - No.1 Private Marine Company in Nigeria"
            />
            <div className='footer-contact-info-item-content'>
              <p className='footer-contact-info-item-title'>
                Administrative Office
              </p>
              <p className='footer-contact-info-item-text'>
              {"No.11 2nd Avenue, Circular Road off Woji, \nAlcon Road, Port Harcourt, Nigeria."}
              </p>
            </div>
          </div>
          {/* <div className='footer-contact-info-item-1'>
            <img 
              src={LocationIcon}
              className='footer-contact-info-item-icon'
              alt="Gelose Marine Services - No.1 Private Marine Company in Nigeria"
            />
            <div className='footer-contact-info-item-content'>
              <p className='footer-contact-info-item-title'>
                Operational Base
              </p>
              <p className='footer-contact-info-item-text'>
              {"Gelose Dock Yard Trans-Woji, \nby Hollifield Jetty, Gbalajam Woji, \nPort Harcourt. Nigeria"}
              </p>
            </div>
          </div> */}
        </div>
        <div className='footer-contact-form'>
          <p className='footer-contact-form-title'>
            Talk To Our Team
          </p>
          <form onSubmit={handle_submit}>
            <span className="footer-input-fields-row">
              <input 
                type='text' 
                value={input_values.full_name} 
                name='full_name'
                placeholder='Full Name'
                onChange={handle_input_change} 
                autoComplete='name'
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className="outline-input-field-1"                
              />
              <input 
                type='email' 
                value={input_values.email} 
                name='email'
                placeholder='Email'
                onChange={handle_input_change} 
                autoComplete='email'
                required
                // className={this.props.is_erred ? "outline-input-field-erred" : (this.state.is_focus ? "outline-input-field-active" : "outline-input-field")}
                className="outline-input-field-2"                 
              />
            </span>
            
            
            <textarea 
              value={input_values.message} 
              onChange={handle_input_change} 
              name='message'
              placeholder="Message"
              expa
              required
            />

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <input type="submit" value="Contact Us" className="submit-button "/>
            </span>
            
            
          </form>
        </div>
      </div>
      <div className='footer-rights'>
        <p>
          © Copyright Gelose Marine Services NIG LTD
        </p>
        {/* <p>Designed & Developed by <a 
          style={{
            fontWeight: 'bolder',
            textDecoration: 'none',
            color: '#fff',
            fontSize: '14px',
          }} 
          href=" https://wa.me/2348089575760"
          target="_blank" 
          rel="noopener noreferrer"
          >YadaTech</a>
        </p> */}
      </div>
    </div>
  )
}

Footer.defaultProps = {
  showCerts: true,
}