import React, {
  useEffect,
  useState,
} from 'react'

import './Compliance.css'

import Footer from '../../components/Footer/Footer'
import PageTitle from '../../components/PageTitle/PageTitle'

import {baseURL2, fetch_certifications} from '../../api'

import Loading from '../../components/Loading/Loading';

export default function Certifications(props) {  
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = useState(true)
  const [certs, setCerts] = useState([])

  useEffect(() => {
    _fetch_certifications()
  }, [])

  const _fetch_certifications = async () => {
    try {
      setLoading(true)
      var res = await fetch_certifications()
      var response = await res.json()
      console.log('response', response)
      if (res.ok) {
        setCerts(response.data)
        setLoading(false)
      } else {
        console.log('response message', response.message)
        setCerts([])
      }
    } catch (error) {
      console.log('error message', error.message)
      setCerts([])
    }
  }

  return (
    <div className='compliance-container'>
      <div className='compliance-body'>
        <PageTitle 
          title='Our Certifications'
        /> 
        {
          loading && (
            <Loading />
          )
        } 
        {/* {
          !loading && (
            <div className='certifications-grid'>
              {
                certs.map((item, index) => {
                  return (
                    <div 
                      className='certifications-card'  
                      key={index}          
                    >
                      <div className='certifications-img-section' >
                        <img 
                          src={baseURL2 + item.image}
                          alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
                        />
                      </div>   
                    </div>
                  )
                })
              }
              
            </div>
          )
        } */}
        {
          !loading && (
            <div className='certifications-flex'>
              {
                certs.map((item, index) => {
                  return (
                    <img 
                      className='certifications-img'
                      key={index}          
                      src={baseURL2 + item.image}
                      alt='Gelose Marine Services NIG LTD, Leading Private Marine Company in Nigeria'
                    />
                  )
                })
              }              
            </div>
          )
        }
        
        <Footer showCerts={false} />
      </div>
    </div>
  )
}