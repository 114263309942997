import React from 'react'
import Lottie from 'react-lottie';

import './Loading.css'

import IconLoading from '../../assets/animations/icon_pulsing.js'

export default function Loading(props) {
  return(
    <div className='loading-container'>
      <Lottie 
        options={{
          loop: true,
          autoplay: true, 
          animationData: IconLoading,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={100}
        width={100}
      />
    </div>
  )
}