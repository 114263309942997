import React, {
  useState,
} from 'react'

import './Compliance.css'

import Footer from '../../components/Footer/Footer'
import PageTitle2 from '../../components/PageTitle2/PageTitle2'
import StaffSignature from '../../components/StaffSignature/StaffSignature'

import CheckMark from '../../assets/svg_icons/check_mark.svg'

export default function HSE(props) {
  return (
    <div className='compliance-container'>
      <div className='compliance-body'>
        <PageTitle2 
          title='Health, Safety & Environment (HSE) Policy Statement'
        />  
        <div className='compliance-contents'>
          <p className='compliance-p-1'>
            <p style={{fontWeight: 'bolder', color: 'white', display: 'inline'}}>GELOSE MARINE SERVICES NIGERIA LIMITED (GMSNL)</p> is an indigenous private maritime security company in Nigeria that provides Technical & Vessel Operational Support, Marine Asset & Risks Management, Vessel Management, Brokerage, and Charter Services.
          </p>
          <p className='compliance-p-2'>
            <p style={{fontWeight: 'bolder', color: 'white', display: 'inline'}}>“We are committed to the prevention of injury, ill-health,  risks, and protection of the environment through</p> waste and noise Management and other specific commitments such as sustainable resource use by managing scarce resources like water, energy, and land in an environmentally sensitive manner; climate change mitigation & adaptation and the protection of biodiversity & ecosystems by looking for ways to minimize risk and protect our employees and the communities in which we operate by employing clean and safe technologies, and operating procedures, as well as being prepared for emergencies.
          </p>
          <p className='compliance-p-2'>
            We shall strive to minimize environmental aspects and occupational health hazards through the use of safe chemicals with considerations to humans and the environment. We shall minimize the amount of waste generated and will ensure safe handling and disposal of waste and the continual improvement of the EMS and OHS performance by constantly reviewing elements of this policy for suitability during management review meetings”.
          </p>
          <p className='compliance-p-2'>
            We shall make this policy available to relevant interested parties and also adhere to all applicable legal requirements, and other compliance obligations the organization chooses to subscribe to.
          </p>
          <p className='compliance-p-2'>
            <p style={{fontWeight: 'bolder', color: 'white', display: 'inline'}}>This policy stated above is appropriate to the scope and nature of our operations</p> defined in the Scope of Integrated Management System (IMS); OH&S hazards/risks as contained in the Hazard Registers, the Context of our Organization as detailed in our Apex Manual, Context of the Organization Procedure, Internal & External Issues Logs, and Needs & Expectations of our relevant Interested Parties; and the environmental impacts of our activities, products, and services as contained in the Environmental Aspects and Impacts Register.
          </p>
          <p className='compliance-p-2'>
            <p style={{fontWeight: 'bolder', color: 'white', display: 'inline'}}>This statement provides a framework for establishing and reviewing of our HSE objectives</p> which shall be developed by each process head and approved by top management.
          </p>
          <p className='compliance-p-2'>
          This policy statement is communicated and understood by all personnel and applied within the organization. It is available to relevant interested parties as appropriate to our organization and also maintained as documented information.
          </p>
          <StaffSignature />
        </div>
        <Footer />
      </div>
    </div>
  )
}