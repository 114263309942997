import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import './NavBar.css'

import { sleep } from "../../utils/sleep";

import Logo from '../../assets/images/GMS-Logo.png'
import Facebook from '../../assets/images/facebook.png'
import Linkedin from '../../assets/images/linkedin.png'
import twitter from '../../assets/images/twitter.png'

import ArrowDropDown from '../../assets/svg_icons/arrowdropdown.svg'
import ArrowDropUP from '../../assets/svg_icons/arrowdropup.svg'
import CloseIcon from '../../assets/svg_icons/close.svg'
import Instagram from '../../assets/svg_icons/instagram.svg'
import MenuIcon from '../../assets/svg_icons/menu.svg'

const MenuList = [
  {
    title: 'Home',
    url: '/',
    sub_menu: [],
  },
  {
    title: 'About Us',
    url: `/about-us`,
    sub_menu: [],
  },
  {
    title: 'Our Services',
    url: `/services`,
    sub_menu: [],
  },        
  {
    title: 'Our Vessels',
    url: `/our-vessels`,
    sub_menu: [],
  },        
  {
    title: 'Company Profile',
    url: `/company-profile`,
    sub_menu: [],
  },     
  {
    title: 'Compliance',
    url: ``,
    sub_menu: [
      {
        title: 'Grievance Policy And Procedures',
        url: `/grievance-policy-and-procedures`
      }, 
      {
        title: 'HSE Policy Statement',
        url: `/hse`
      }, 
      {
        title: 'Quality Policy Statement',
        url: `/quality-policy-statement`
      }, 
      {
        title: 'Our Certifications',
        url: `/certifications`
      }, 
    ]
  },    
  // {
  //   title: 'Blog & News',
  //   url: '/blog',
  //   sub_menu: [],
  // },
  {
    title: 'Contact Us',
    url: '/contact-us',
    sub_menu: [],
  },
  {
    title: 'Blog',
    url: 'https://blog.gelosemarine.com/',
    sub_menu: [],
  },
]

export default function NavBar (props) {
  const [is_menu_icon_clicked, setMenuIconClicked] = React.useState(false);  
  const [anchorElement, setAnchorElement] = React.useState(null);
  const isOptionsOpen = Boolean(anchorElement);
  const [is_menu_opened, setMenuOpened] = React.useState(false);
  const [show_sub_menu, setShowSubMenu] = React.useState(false);
  const [active_sub_menu_index, setActiveSubMenuIndex] = React.useState();
  const location = useLocation()


  const handle_menu_icon_click = () => {
    setMenuIconClicked(!is_menu_icon_clicked);
  };


  const close_menu = async () => {
    
    setMenuOpened(false)
    
    await sleep(1000)
  }

  const toggle_sub_menu = (index, event) => {
    if (active_sub_menu_index === index) {
      setShowSubMenu(false)
      setActiveSubMenuIndex(null)
      setAnchorElement(null);
      close_menu()
    } else {
      setAnchorElement(event.currentTarget);
      setActiveSubMenuIndex(index)
      setShowSubMenu(true)
    }
  }

  const toggle_sub_menu2 = (index, ) => {
    if (active_sub_menu_index === index) {
      setShowSubMenu(false)
      setActiveSubMenuIndex(null)
      close_menu()
    } else {
      setActiveSubMenuIndex(index)
      setShowSubMenu(true)
    }
  }

  const closeOptions = () => {
    setAnchorElement(null);
    setShowSubMenu(false)
  };

  const menu_list = MenuList.map((item, index, array) => {
    if(item.sub_menu.length === 0) {
      return (
        <div 
          className='nav-item'
          key={index}
        >                    
          {item.title == 'Blog' ? (
            <a 
              className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
              href='https://blog.gelosemarine.com/'
              onClick={close_menu}            
            >
              {item.title}
            </a>
          ) : (
            <NavHashLink 
              exact to={item.url} 
              smooth
              className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
              onClick={close_menu}            
            >
              {item.title}
            </NavHashLink>
          )}
        </div>
      )
    } else {
      return (
        <div 
          className='nav-item-2'
          key={index}
          
        >
          <div 
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link-2"}
            onClick={(event) => toggle_sub_menu(index, event)}            
          >
            <>
              {item.title}
            </>
            {
              (show_sub_menu && active_sub_menu_index === index) ? (
                <img 
                  src={ArrowDropUP}
                  style={{
                    width: '10px',
                  }}                
                />
              ) : (
                <img 
                  src={ArrowDropDown}
                  style={{
                    width: '10px',
                  }}                
                />
              )
            }
          </div>
          {/* {
            show_sub_menu && active_sub_menu_index === index && (
              <div className='sub-menu'>
                {
                  item.sub_menu.map((sub_item, sub_index) => {
                    return (
                      <div 
                        className='sub-item'
                        key={sub_index}
                      >          
                        <NavHashLink 
                          exact to={sub_item.url} 
                          smooth
                          className='sub-item-link'
                          onClick={() => toggle_sub_menu(index)}            
                        >
                          {sub_item.title}
                        </NavHashLink>
                      </div>
                    )
                  })
                }
              </div>
            )
          } */}
          <Menu
            id="basic-menu"
            anchorEl={anchorElement}
            open={isOptionsOpen}
            onClose={closeOptions}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            style={{
              zIndex: 99999999999999999999
            }}
          >            
            {
              item.sub_menu.map((sub_item, sub_index) => {
                return (
                  <MenuItem>
                    <NavHashLink 
                      exact to={sub_item.url} 
                      smooth
                      className='menu-sub-item-link'
                      onClick={() => toggle_sub_menu(index)}            
                    >
                      <ListItemText>{sub_item.title}</ListItemText>
                    </NavHashLink>                    
                  </MenuItem>
                )
              })
            }
          </Menu>
        </div>
      )
    }
    
  })

  const mobile_menu_list = MenuList.map((item, index, array) => {
    if(item.sub_menu.length === 0) {
      return (
        <div 
          className='nav-item'
          key={index}
        >          
          {item.title == 'Blog' ? (
            <a 
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
              href='https://blog.gelosemarine.com/'
              onClick={close_menu}            
            >
              {item.title}
            </a>
          ) : (
            <NavHashLink 
              exact to={item.url} 
              smooth
              className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link"}
              onClick={close_menu}            
            >
              {item.title}
            </NavHashLink>
          )}
        </div>
      )
    } else {
      return (
        <div 
          className='nav-item-2'
          key={index}
          
        >
          <div 
            className={`${location.pathname}${location.hash}` === item.url ? "nav-link-active" : "nav-link-2"}
            onClick={() => toggle_sub_menu2(index)}            
          >            
            {
              (show_sub_menu && active_sub_menu_index === index) ? (
                <img 
                  src={ArrowDropUP}
                  style={{
                    width: '10px',
                  }}                
                />
              ) : (
                <img 
                  src={ArrowDropDown}
                  style={{
                    width: '10px',
                  }}                
                />
              )
            }
            <>
              {item.title}
            </>
          </div>
          {
            show_sub_menu && active_sub_menu_index === index && (
              <div className='sub-menu'>
                {
                  item.sub_menu.map((sub_item, sub_index) => {
                    return (
                      <div 
                        className='sub-item'
                        key={sub_index}
                      >          
                        <NavHashLink 
                          exact to={sub_item.url} 
                          smooth
                          className='sub-item-link'
                          onClick={() => toggle_sub_menu2(index)}            
                        >
                          {sub_item.title}
                        </NavHashLink>
                      </div>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      )
    }
    
  })

  return (
    <>
      <div className='nav-bar'>
        <div className='nav-bar-logo-tagline'>
          <a href='/'>
            <img 
              src={Logo}
              className='logo'
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
            />
          </a>  
          <p>Integrity in service...</p>
        </div>

        <div className={"nav-list"}>
          {menu_list}
        </div>

        <div className='nav-footer'>
          <div className='nav-socials'>
            <a href='https://www.facebook.com/profile.php?id=100084607550042'>
              <img
                className='nav-socials-icon-img'
                src={Facebook}
              />
            </a>
            {/* <a href='#'>
              <img
                className='nav-socials-icon-img'
                src={Instagram}
              />
            </a> */}
            <a href='https://twitter.com/Gelose_Marine'>
              <img
                className='nav-socials-icon-img'
                src={twitter}
              />
            </a>
            <a href='https://www.linkedin.com/in/gelose-marine-services/'>
              <img
                className='nav-socials-icon-img'
                src={Linkedin}
              />
            </a>

          </div>
          <p>
            © Copyright GMS NIG LTD
          </p>
          {/* <p>Developed by <a 
            style={{
              fontWeight: 'bolder',
              textDecoration: 'none',
              color: '#fff',
              fontSize: '12px',
            }} 
            href=" https://wa.me/2348089575760"
            target="_blank" 
            rel="noopener noreferrer"
            >YadaTech</a>
          </p> */}
        </div>
        
      </div>
      <div className='mobile-nav'>
        <div className='mobile-nav-bar'>
          <div className='mobile-nav-bar-header'>          
            <a href="/">
            <img 
              src={Logo}
              alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
              className='mobile-logo'
            />
            </a>

            <div className="menu-icon" onClick={() => setMenuOpened(true)}>
              <img
                src={MenuIcon}
                alt='Menu Icon'
                className='menu-icon-img'
              />
            </div>
          </div>
          <div 
            // className='mobile-nav-drawer'
            className={is_menu_opened ? 'mobile-nav-drawer-open' : 'mobile-nav-drawer-close'}
          >
            <div 
              className='mobile-nav-drawer-overlay'
              onClick={() => setMenuOpened(false)}
            />
            <div className='mobile-nav-drawer-content'>
              <div className='mobile-drawer-header'>  
                <div className="menu-icon" onClick={() => setMenuOpened(false)}>
                  <img
                    src={CloseIcon}
                    alt='Menu Icon'
                    className='menu-icon-img'
                  />
                </div>        
                <a href="/">
                <img 
                  src={Logo}
                  alt='Gelose Marine Services NIG LTD, NO.1 Private Marine Company in Nigeria'
                  className='mobile-logo'
                />
                </a>              
              </div>
              <div className="mobile-nav-list">
                {mobile_menu_list}
              </div>
              <div className='mobile-nav-socials'>
                <a href='https://www.facebook.com/profile.php?id=100084607550042'>
                  <img
                    className='nav-socials-icon-img'
                    src={Facebook}
                  />
                </a>
                {/* <a href='#'>
                  <img
                    className='nav-socials-icon-img'
                    src={Instagram}
                  />
                </a> */}
                <a href='https://twitter.com/Gelose_Marine'>
                  <img
                    className='nav-socials-icon-img'
                    src={twitter}
                  />
                </a>
                <a href='https://www.linkedin.com/in/gelose-marine-services/'>
                  <img
                    className='nav-socials-icon-img'
                    src={Linkedin}
                  />
                </a>

              </div>
            </div>
            
          </div>
          
          {/* <ul className={is_menu_icon_clicked ? "menu-list" : "menu-list close"}>
            {menuList}
          </ul> */}
        </div>
      </div>
    </>
  )
}