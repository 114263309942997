import React from 'react'

import './Services.css'

import Footer from '../../components/Footer/Footer'
import PageTitle3 from '../../components/PageTitle3/PageTitle3'

import CheckMark from '../../assets/svg_icons/check_mark.svg'
import Repairs from '../../assets/images/repairs.png'

export default function ShipRepairs(props) {
  return (
    <div className='services-container'>
      <div className='services-body'>
        <PageTitle3
          title='Ship Repairs & Dry Docking'
        />
        <div className='service-details'>
          <div className='service-details-content'>
            <p className='service-content-p'>
              One of our core services is Ship Repairs & Dry Docking. Since inception, we have carried out over 30 dry docking and ship repair projects with services ranging from Removal and Installation of Propeller Shafts, repowering of vessels to increase its speed, Main Engine Overhaul and generator overhaul, welding and fabrication for both steel and aluminum. We also provide ship redesign and construction to suit modern needs for retrofitting, enhance operability of the vessel or enhance the ships aesthetics.
            </p>
            <p className='service-content-p-1'>
              We currently operate from our Jetty which occupies a total land area of 5770 sqm with approximately 110m of waterfront. Most of the ship lifting to access the underwater of the vessel are done by the use of a crane, however, the company is developing a modern shipyard with a Travelift of 400 ton lifting capacity with the capability to dry dock up to 5 vessels simultaneously.
            </p>
            <p className='service-content-p-1'>
              Our Ship Repairs and DryDocking Services includes:
            </p>
            <ol className='service-content-list'>
              <li className='service-content-list-item'>
                Welding and Fabrication
              </li>
              <li className='service-content-list-item'>
                Mechanical Services (Propeller repairs, Rudder Repairs, Shaft alignment, Engine overhaul etc.)
              </li>
              <li className='service-content-list-item'>
                Hull Protection (Blasting and Painting)
              </li>
              <li className='service-content-list-item'>
                Berthing Services
              </li>
              <li className='service-content-list-item'>
                Electrical and Navigational Equipment Repairs
              </li>
              <li className='service-content-list-item'>
                Carpentry and Plumbing services
              </li>
            </ol>
          </div>
          <div className='service-details-img'>
            <img 
              src={Repairs}
              alt='Ship Repairs & Dry Docking'
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}